import React from "react";
import { Trans, useTranslation } from 'react-i18next';
import {  Container, Row, Col } from 'react-bootstrap';
import Obfuscate from "react-obfuscate"

function Contact(){
    const {t} = useTranslation();
    return(
        <Container>
        <Row>
            <Col className="text-center">
            <h1>{t('link_to.contact.display')}</h1>
            </Col>
        </Row>
        <Row>
            <Col />
            <Col md={9} lg={6}>
             
                 {t('section.contact.intro')}:
                   <ul>
                   <li>
                        <Trans i18nKey={'section.contact.email'}>
                            Instruction to send email to <Obfuscate  email="karin@interacta.se" linkText={'Karin Folkeryd'} obfuscateChildren={false}>Name</Obfuscate>
                    </Trans>
                    </li>
 
                   </ul>
            </Col>
            <Col />
        </Row>
    </Container>

)
}

export default Contact
import React from "react";
import { Trans, useTranslation } from 'react-i18next';
import {  Container, Row, Col } from 'react-bootstrap';

function Background(){
    const {t} = useTranslation();
    return(
       <Container>
                <Row>
                    <Col className="text-center">
                    <h1>{t('link_to.background.display')}</h1>
                    </Col>
        </Row>
        <Row>
            <Col/>
            <Col md={9} lg={6}>
                <ul>
                    {[1,2,3,4,5,6].map(itm => (<li>{t('section.background.item-'+itm)}</li>))}
                    <li>
                    <Trans i18nKey={'section.background.item-7'}>
                        Intro text with <a href="https://www.sfoe.se">clickable link</a>
                    </Trans>
                    </li>
                </ul>
            </Col>
            <Col/>
        </Row>
    </Container>


        )
}

export default Background
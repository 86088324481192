import React from "react";
import {Trans, useTranslation } from 'react-i18next';
import {  Container, Row, Col } from 'react-bootstrap';
const logoRow='./interacta-row.svg';

function Hero(){
    const {t} = useTranslation();
        return(

            <Container id={'hero'} className={'ia-blue text-center'}>
            <Row>
                <Col >
                <img className="img-fluid w-75" src={logoRow} alt="Interacta logo" />
                </Col>
            </Row>
            <Row>
                <Col>
                <h1 className='mr-auto'>&#8211; {t('slogan')} </h1>
                </Col>
            </Row>
            <Row>
                <Col id={'catcher'}>
                    <Trans i18nKey={'catcher'} />
                </Col>
            </Row>
        </Container>

/*          <div className="d-flex align-items-md-center min-vh-100">
                <div className="container">
                    <div className="row align-items-start align-items-lg-center">

                    </div>
                </div>
        </div> */
        );
    
};

export default Hero
import React from "react";
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
  Outlet,
  Navigate
} from "react-router-dom";
import LanguageSelector from "./components/LanguageSelector";
import Navigation from "./components/Navigation"
import Hero from "./components/Hero"
import Services from "./components/Services"
import Background from "./components/Background";
import Contact from "./components/Contact"
const router = createBrowserRouter([
  { path: "*", Component: Root },
]);


function Chrome(){
        return(
            <React.Fragment>
                <LanguageSelector />
                <Outlet  />
                <Navigation />
            </React.Fragment>
        );
    
};
function Root() {
  return (
    <Routes>
      <Route path="/" element={<Chrome />} >
        <Route exact path="/" element={<Hero />} />
        <Route path="/tjanster" element={<Services/>} />
        <Route path="/services" element={<Services />} />
        <Route path="/bakgrund" element={<Background />} />
        <Route path="/background" element={<Background />} />
        <Route path="/kontakt" element={<Contact />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>

{/*       <Route path="/blog/*" element={<BlogApp />} />
      <Route path="/users/*" element={<UserApp />} /> */}
    </Routes>
  );
}

export default function App() {
  return <RouterProvider router={router} />;
}

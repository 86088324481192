import React from "react";
import { Trans, useTranslation } from 'react-i18next';
import {  Container, Row, Col } from 'react-bootstrap';

function Services(){
    const {t} = useTranslation();
    return(
        <Container>
        <Row>
            <Col className="text-center">
            <h1>{t('link_to.services.display')}</h1>
            </Col>
        </Row>
        <Row>
        <Col />
            <Col md={9} lg={6}>                 
            <Trans i18nKey={'section.services.list'}>
                 {t('section.services.intro')}:
                   <ul>
                      {[1,2,3,4,5,6].map(itm => (<li>{t('section.services.item-'+itm)}</li>))}
                   </ul>
                </Trans> 
            </Col>
            <Col />
        </Row>
    </Container>

)
}

export default Services
import {React, useEffect,useState} from 'react';
import { useTranslation } from 'react-i18next';
import {  Container, Row, Col, Button } from 'react-bootstrap';
import {  Link, useLocation  } from "react-router-dom";
const logoRow='./interacta-row.svg';

function LanguageSelector(){
    const { i18n} = useTranslation();
    const isActiveLanguage = (lng) => lng===i18n.language;
    const changeLanguage = (lng) => i18n.changeLanguage(lng);
    const location = useLocation();
    const [showLogo, setShowLogo] = useState(false);
    useEffect(() => {
        location.pathname==='/'?setShowLogo(false):setShowLogo(true)
    }, [location]);
    return (
         
        <Container>
            <Row className='align-items-center' id='lng-sel-row'>
                <Col className={showLogo?'text-start':'d-none'} >
                <Link to='/' reloadDocument>
                    <img className="logo-small" src={logoRow} alt="Interacta logo" />
                </Link>
                </Col>
                <Col className='text-end'>
                        <Button 
                            variant="outline-secondary"
                            size='sm'
                            disabled={isActiveLanguage('en')}
                            onClick={()=>changeLanguage('en')}
                        >
                            en
                        </Button>
                        <Button
                            variant="outline-secondary"
                            size='sm'
                            disabled={isActiveLanguage('sv')}
                            onClick={()=>changeLanguage('sv')}
                        >
                            sv
                        </Button>
                </Col>
            </Row>
        </Container>
    );

}
export default LanguageSelector;
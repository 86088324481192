import {React, useEffect,useState} from 'react';
import { useTranslation } from 'react-i18next';
import {  Container, Row, Col } from 'react-bootstrap';
import {  Link, useLocation  } from "react-router-dom";

function Navigation(){
    const {t} = useTranslation();
    const location = useLocation();
    const [fixNav, setFixNav] = useState(false);
    useEffect(() => {
        location.pathname==='/'?setFixNav(true):setFixNav(false)
    }, [location]);
  
    return(<Container className={"text-center " + (fixNav?'fix-nav':'')} >
            <Row>
               <Col className='d-block d-md-none' sm={true} lg={3} xl={6}/>
                <Col className={"text-center"} sm={3} md={4}>
                    <Link to={t('link_to.services.address')} >{t('link_to.services.display')}</Link>
                </Col>
                <Col className={"text-center"} sm={3} md={4}>
                    <Link to={t('link_to.background.address')} >{t('link_to.background.display')}</Link>
                </Col> 
                <Col className={"text-center"} sm={3} md={4}>
                    <Link to={t('link_to.contact.address')} >{t('link_to.contact.display')}</Link>
                </Col>
                <Col className='d-block d-md-none' sm={true} lg={3} xl={6}/>
            </Row>
        </Container>)
}


//todo
/* 
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function TriggerExample() {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Simple tooltip
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <Button variant="success">Hover me to see</Button>
    </OverlayTrigger>
  );
}

export default TriggerExample;
*/
export default Navigation